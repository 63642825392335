/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.buttonLogin {
  margin: 10px !important;
  padding: 20px;
  width: 150px !important;
}

#root {
  height: 100%;
  overflow: hidden;
}

html,
body {
  height: 100%;
  margin: 0px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.card {
  margin: auto;
  background-color: rgb(245, 245, 245) !important;
  border-radius: 10px !important;
  margin-top: 10px;
}

.tituloCardTest {
  border-radius: 10px !important;
  text-align: center;
}

.buscador {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 10px;
  margin-top: 30px;
  width: 280px;
  margin-bottom: 30px;
}
.buscador2 {
  width: 280px;
}
.botonAgrReg {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.body {
  display: flex;
  /* align-items:flex-start; */
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 20px;


}
.bodyHome{
  display: flex;
  align-items:flex-start;
  justify-content:space-evenly;
  flex-wrap: wrap;
  gap: 150px;
  margin-top: 50px;
  
  
}
.homediv{
  display: flex;
  align-items: center;
  flex-direction: column;
  /* justify-content: center; */
  /* flex-wrap: wrap;
  gap: 20px; */
  margin-top: 25px;
}
.bodyRegForm {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 25px;
}

.textfield {
  margin-top: 30px !important;
  display: flex !important;
  width: 400px !important;
}

.slider {
  margin-top: 30px !important;
  display: flex !important;
  width: 400px !important;
  flex-direction: column;
}

.checkbox {
  margin-top: 20px !important;
  display: flex !important;
  width: 400px !important;
  flex-direction: row;
  align-items: center;
  justify-content: space-around !important;
}

.card {
  margin: 10px;
  flex-grow: 4;
}
.card2{
  background-color: rgb(245, 245, 245) !important;
  border-radius: 10px !important;
  margin-top: 10px;
  height: 150px;
  width: 320px;
  border: 1px solid rgb(141, 141, 141)
}
.errorYup {
  font-size: 12px !important;
  color: #FF0D10 !important;
  /* margin-left: -290px;
    margin-top: -36px; */
  /* position: relative;
    left: 14px;
    top:-15px; */


}

.botonera {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin: 25px;
}

.pageRegistradores {
  margin-top: 70px;
}

.pageHistorial {
  margin-top: 25px;
}

.snackbar {
  margin-left: 60px;
}

.homePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  /* align-items: center; */
  /* justify-content: center; */
  margin-top: 100px;
}
.cardHeader{
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}
.tablaInc {
  padding: 40px;

}

.tableHeader {
  background-color: #f1f1f1;
}

.formBajo {

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;


}

.paperInc {
  margin: 30px;
  padding: 30px;
}

.cardInc {
  /* margin: auto; */
  /* background-color: rgb(245, 245, 245) !important; */
  border-radius: 10px !important;
  border: 1px solid rgb(141, 141, 141)
}

.textfield2 {
  margin-top: 15px !important;
  display: flex !important;
  width: 650px !important;
}

.tablaBaja {
  margin-top: 25px;
}

.pageConfiguracion {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.pageCardForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.cardContent{
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}

.cardCardForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px !important;
  /* min-height: 400px;
  min-width: 600px; */
  /* width: 100%; */
  /* padding: 20px; */
  background-color: rgb(245, 245, 245) !important;
  border-radius: 10px !important;

}

.bodyList {
  
  margin: auto;
  max-width: 1300px;
}
.bodyList2{
  /* width: auto;
  height: auto; */
  /* padding-right: 80px; */
  /* margin: 40px; */
}
.pageDialog {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
}

.cardList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.buscadorCards {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 15px auto;
}

.bgImagen {
  height: 100%;
  /* background-size: cover; */
  background-size: cover;
  background-image: url("./components/img/fondoVar4.jpg");
  background-position: center;
}

.bgImagen2 {
  height: 100%;
  width: 100%;
  background-size: auto;
  /* background-image: linear-gradient(to left, rgba(97, 96, 96, 0), rgb(233, 230, 230)), url("https://www.todofondos.net/wp-content/uploads/descargar-66-white-wallpaper-para-escritorio-y-movil.-imagen-hd-1080p-blancos-768x432.jpg");  */
  /* background-image: url('https://www.google.com/url?sa=i&url=https%3A%2F%2Fblog.radware.com%2Fprogramming-code-abstract-technology-background-of-software-developer-and-computer-script-2%2F%3Flang%3Des&psig=AOvVaw1WK0pcpzyc8yLTkZ-77Y9L&ust=1650542243569000&source=images&cd=vfe&ved=0CAwQjRxqFwoTCLjamJfLovcCFQAAAAAdAAAAABAg');  */
  background-image: linear-gradient(to left, rgba(212, 211, 211, 0.534), rgb(233, 230, 230)), url("./components/img/fondoVar4.jpg");
  background-position: right;
  /* filter: blur(2px);*/
  /* overflow-x: scroll; */
  /* padding-bottom: 3px; */
}
.logoVarilleros{
  height: 40px;
  width: 160px;
  margin: 12px;
  margin-left: 50px;
}
.logoVarilleros2{
  height: 35px;
  width: 35px;
  margin: 14.5px;
  margin-left: 22px;
}
.textfield3 {
  /* margin: 15px !important; */
  background-color: white;
  /* width: 300px !important; */
  /* display: flex !important; */
  /* width: 100px !important; */
  margin-bottom: 30px !important; 
  width: 300px !important;
}

.login2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  /* background-color: #EABA6B; */
  background-image: linear-gradient(to bottom, #e9d5d58a, #e9bebe96, #e09f9f8e, #e68b8bad, #e96363b2);
  padding: 30px;
  border-width: 10px;
  border-radius: 10px;
  border: 1px solid black !important;
}



.login {
  height: 100%;
  width: 100%;
  position:absolute;
  display: flex;
  justify-content: center;
  align-items: center;  
}

.logoLogin {
  /* height: 50px; */
  width: 240px;
  margin: 10px;
  margin-bottom: 30px;
  /* margin-left: 80px; */
}
.logoHome{
  width: 300px;
}
.nombreApp {
  color: #f1f1f1;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-weight: bolder;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;

}

.cargando {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20%;
}

.cargando>* {
  margin-bottom: 15px;
}

.varForm {
  text-align: center;
}

.varForm--textFields {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 30px;
}

.varForm--textFields--content {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.varForm--textFields--content2 {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin:10px;
}

.varForm--textFields2 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-end;
  margin: 0 30px
}

.varForm--textFields--content>*>* {
  width: 300px;


}

.varForm--textFields--content>*>* {
  margin: 10px;
  background-color: white !important;

}


.varForm--textFields--content2>*>* {
  width: 200px;
  background-color: white !important;
}

.varForm--firma-fechaF{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin:10px;
}

.fechaFirma {
  width: 200px;
  background-color: white !important;
  margin-left:10px !important;
}

.varForm--textFields--content2>*>*>* {
  /* margin: 10px */
}

.varForm--textFields--content--invisible>*>* {
  width: 200px;
  opacity: 0;
}

.varForm--selectors {
  margin: 10px;
  width: 280px;
  background-color: white !important;
}

.varForm--table {
  text-align: center;
  
}

.varForm--title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: xx-large;
  color: rgb(66, 66, 66);
  margin: 10px;
}

.varForm--cliente-vehiculo {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
}

.varForm--cliente-vehiculo--content>*>.varForm--textFields--content>*>* {
  width: 250px;
}

.varForm--textFields--observaciones {
  margin: auto;
  margin: 10px 40px;
}

.varForm--textFields--observaciones>*>* {
  width: 100%;
  background-color: white !important;
}

.varForm--txt {
  text-align: center;
  margin: 2px;
  color: #535353;
}

.varForm--buttons {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 10px;
}

.varForm--buttons>* {
  width: 200px;
}

.varForm--ocultarDescuento {
  display: flex;
}

.varForm--ocultarDescuento>* {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cardTrans3 {
  /* flex-wrap: wrap; */
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* width: 100%; */
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  background-color: rgb(245, 245, 245, 0.6) !important;
  border-radius: 5px !important;
  padding-bottom: 30px;
}
.cardTrans4 {
  /* flex-wrap: wrap; */
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* width: 100%; */
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  background-color: rgb(245, 245, 245, 0.7) !important;
  border-radius: 10px !important;
  padding-bottom: 30px;
  border: 1px solid rgb(141, 141, 141);
  max-width: fit-content;

  /* background-color: rgb(245, 245, 245) !important;
  border-radius: 10px !important;
  margin-top: 10px;
  height: 150px;
  width: 320px;
  border: 1px solid rgb(141, 141, 141) */
}
.clienteForm{

}

.signatureCanvas {
  margin: 10px;
  border: 1px solid black;
  border-radius: 5px;
  width: 200px;
  min-height: 100px;
  background-color: #ffff;
}
.noData {
  text-align: center;
  height: 100px;
  width: 100%;
  background-color: white;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.firma{
  display: flex;
  flex-wrap: wrap;
}

.firma--buttons{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.varForm--calculos{
  margin: auto;
  margin-left: 30px;
  margin-right: 30px;
}

.varForm--calculos--line{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
}

.versionNumber{
  position: absolute;
  margin-right: 10px;
  bottom: 0;
  right: 0;
}