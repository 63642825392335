.cardPres--title{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: large;
  padding: 10px;
  font-weight: bold;
  margin: -10px;
  border-radius: 10px 10px 0px 0px;
}

.cardPres--title--green{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: large;
  background-color: rgb(195, 236, 195);
  padding: 10px;
  font-weight: bold;
  margin: -10px;
  border-radius: 10px 10px 0px 0px;
}

.cardPres--title--yellow{
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  font-size: large;
  background-color: rgb(236, 247, 175);
  padding: 10px;
  font-weight: bold;
  margin: -10px;
  border-radius: 10px 10px 0px 0px;
}

.cardPres--title--blue{
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  font-size: large;
  background-color: rgb(195, 215, 245);
  padding: 10px;
  font-weight: bold;
  margin: -10px;
  border-radius: 10px 10px 0px 0px;
}

.cardPres--title--grey{
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  font-size: large;
  background-color: rgb(187, 187, 187);
  padding: 10px;
  font-weight: bold;
  margin: -10px;
  border-radius: 10px 10px 0px 0px;
}