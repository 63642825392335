#page {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-inner-item
  > .pro-icon-wrapper {
  display: inline-block !important;
}

.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-inner-item
  .pro-arrow-wrapper {
  display: inline-block !important;
}

.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-inner-item:before {
  content: none !important;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
  padding-bottom: auto;
}

#main {
  height: 100%;
  width: 100%;
  overflow-x: scroll;
}
#page .pro-sidebar-inner .pro-sidebar-layout .active {
  /* background-image: linear-gradient(0deg, #fece00 0%, #ffe172 100%); */
  border-radius: 12px !important;
  margin: 3px;
  background-image: linear-gradient(to top, #7c7c7c94, #74666738, #49363738, #7c333854, #4e0a0f5b);
}
.bar {
  position: inherit !important;
  background-color: rgb(255, 255, 255)!important;
  /* background-image: linear-gradient(to right, #ffffff, #ffffff, #ffffff, #ffffff, #ec2c2c); */
  box-shadow: none !important;
  display: flex !important;
  align-items:center !important;
  width: 100%;
  border-bottom: solid rgb(206, 206, 206) 1px;
}

.tool {
  display: flex !important;
  /* align-items: center !important; */
  justify-content: space-between !important;
  width: 100%;
}
#menu {
  z-index: 1101;
}

#smalltext {
  margin-left: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
}
#text{
  margin-left: 90px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
}

.title{
  display: flex;
  color: rgb(78, 78, 78) !important;
  font-size: x-large !important;
  flex-grow: 2 !important;
  margin-left: -80px!important;
  align-items: center;
}

.titleText{
  margin-left: 5px;
}

#appContent{
  overflow-y: scroll;
  height: calc(100% - 65px);
  width: 100%;
  /* overflow-x: scroll; */
}
#appContent2{
margin: 10px;
}


.icon{
  display: flex;
  
}

.navBar{
  display: flex;
  flex-direction: row;
  justify-content: space-around !important;
  align-items: center;
  /* width: 100%; */
}

.navBar3{
  color: rgb(70, 70, 70) !important;
}
  