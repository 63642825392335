.card{
  max-width: 450px;
  width: 400px;
  padding: 10px;
  color: rgb(66, 66, 66);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.card--title{
  display: flex;
  align-items: flex-end;
  font-weight: bold;
  justify-content: space-between;
  font-size: large;
}

.card--content{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 5px;
  color: rgb(41, 41, 41);
  overflow: auto;
}

.card--label{
  display: inline-flex;
  align-items: center;
  color: rgb(100, 100, 100);
  cursor: pointer;
}

.card--button{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}

.card--button > .card--label{
  color: rgb(41, 41, 41);
}

.card--infoContainer{
  height: 100%;
}

.buscadorTabla{
  display: flex;
  justify-content: space-between;
}