/*  Dialog usado en MODELOS, PIEZAS, RANGO BOLLOS y PRECIO */

.dialog--one{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  min-width: 400px;
}

.dialog--one--title{
  margin-top: 10px;
  width: 100%;
  text-align: center;
}

.dialog--one--column > *{
  padding:10px; 
}

.dialog--one--buttons{
  /* width: 250px; */
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  padding:10px; 
}

.dialog--one--buttons > *{
  width: 150px !important;
  margin: 25px !important;
}

/*  Dialog contacto  */

.dialog--contacto{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  min-width: 400px;
}

.dialog--contacto--content{
  display: flex;
  flex-direction: column;
}

.dialog--contacto--title{
  text-align: center;
  margin-top: 10px;
}

.dialog--contacto--row{
  display: flex;
  flex-direction: column;
}

.dialog--contacto--column{
  width: 600px;
  display: flex;
  justify-content: space-evenly;
  padding: 10px;
}

.dialog--contacto--comentarios{
  display: flex;
  justify-content: space-evenly;
  padding: 10px;
}

.dialog--contacto--comentarios > *{
  text-align: center;
  width: 100%;
  height: 100%;
}

.dialog--contacto--comentarios > * > *{
  width: 85%;
}

/* Dialog Precio */

.dialog--precio{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  min-width: 400px;
}

.dialog--precio--content{
  display: flex;
  flex-direction: column;
}

.dialog--precio--title{
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.dialog--precio--row{
  display: flex;
  flex-direction: column;
}

.dialog--precio--column{
  width: 600px;
  display: flex;
  justify-content: space-evenly;
  padding: 10px;
}

.dialog--precio--column > * > *{
  width: 250px;
}

.dialog--precio--checkbox{
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
}